@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Readex Pro";
    overflow-x: hidden;
}

/* ===== Scrollbar Customization CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #000000 #ebebeb;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #bebfc0;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #360208;
    border-radius: 10px;
    border: none;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #fe8740;
}

#element {
    overflow: scroll;
    scrollbar-width: none; 
   
  }

  #element::-webkit-scrollbar {
    display: none;
  }

  .spinner {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 0.5px;
    background: conic-gradient(#0000 10%,#49ff47) content-box;
    -webkit-mask: repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
         radial-gradient(farthest-side,#0000 calc(100% - 3.8px),#000 calc(100% - 3.8px));
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: spinner-d55elj 0.8s infinite steps(10);
 }
 
 @keyframes spinner-d55elj {
    to {
       transform: rotate(1turn);
    }
 }